var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container-page"},[_c('v-card',{staticClass:"mt-0 transparent"},[_c('v-card-text',{staticClass:"py-0 px-0"},[(_vm.loading > 0)?[_c('loading',{staticClass:"absolute-center"})]:[_c('v-card',{staticClass:"transparent elevation-0 card-pt-0"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('h1',{staticClass:"main-title mb-6"},[_vm._v(" Acordos ")])]),_c('v-data-table',{staticClass:"transparent table-sticky table-clicked",attrs:{"no-data-text":"Não há negociações criadas","item-key":"id","options":_vm.pagination,"headers":_vm.headers,"items":_vm.proposals,"footer-props":_vm.footerProps,"server-items-length":_vm.proposals.length,"single-expand":true,"expanded":_vm.expanded},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
return [_c('tr',{class:{'table--selected elevation-3': expand.props.value},on:{"click":function($event){return _vm.toggleExpand(item)}}},[_c('td',{staticClass:"checked-action",attrs:{"tabindex":"0"}},[(expand.props.value)?_c('v-icon',{staticClass:"v-align-center mr-2",attrs:{"size":"20px"}},[_vm._v(" "+_vm._s(_vm.$root.icons.state.checked)+" ")]):_c('v-icon',{staticClass:"v-align-center mr-2",attrs:{"size":"20px"}},[_vm._v(" "+_vm._s(_vm.$root.icons.state.unchecked)+" ")])],1),_c('td',{staticClass:"after-checked-action"},[_vm._v(" "+_vm._s(item.code)+" ")]),_c('td',[_c('promise-key',{attrs:{"promise":item.case,"promise-key":"recipient.name"}})],1),_c('td',[_vm._v(_vm._s(_vm.$n(item.installmentSelected.originalValue, 'currency')))]),_c('td',[_c('v-tooltip',{attrs:{"top":"","content-class":"table_trasparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" info ")])],1)]}}],null,true)},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((item.installmentSelected.details),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.$t('calculateParams.' + item.type)))]),_c('td',[_vm._v(_vm._s(_vm.$n(item.value, 'currency')))])])}),0)])],1),_vm._v(" "+_vm._s(_vm.$n(_vm.additions(item.installmentSelected.details), 'currency'))+" ")],1),_c('td',[_c('v-tooltip',{attrs:{"top":"","content-class":"table_trasparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" info ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.installmentSelected.parcel)+" x "+_vm._s(_vm.$n(item.installmentSelected.parcelValue, 'currency')))])]),_vm._v(" "+_vm._s(_vm.$n(item.installmentSelected.currentValue, 'currency'))+" ")],1),_c('td',[_vm._v(_vm._s(item.updatedAt))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_c('h2',{staticClass:"subtitle-1 mb-2 darkGray--text"},[_c('v-icon',{staticClass:"icon-align-text mr-1",attrs:{"size":"22px","color":"darkGray"}},[_vm._v(" "+_vm._s(_vm.$root.icons.layout.label)+" ")]),_vm._v(" Termo: "),_c('v-btn',{attrs:{"href":item && item.term && item.term.fileUrl,"target":"blank","depressed":"","small":"","color":"accent"}},[_vm._v(" Visualizar ")])],1),_c('v-data-table',{staticClass:"transparent table-sticky",attrs:{"options":_vm.bankSlipPagination,"headers":_vm.bankSlipHeaders,"items":item.ticketsPayments,"hide-default-footer":""},on:{"update:options":function($event){_vm.bankSlipPagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.numDoc))]),_c('td',[_vm._v(_vm._s(props.item.parcel))]),_c('td',[_vm._v(_vm._s(_vm.$n(props.item.details.correctionIndex, 'currency')))]),_c('td',[_vm._v(_vm._s(_vm.$n(props.item.details.fine, 'currency')))]),_c('td',[_vm._v(_vm._s(_vm.$n(props.item.details.interestRate, 'currency')))]),_c('td',[_vm._v(_vm._s(_vm.$n(props.item.details.fees, 'currency')))]),_c('td',[_vm._v(_vm._s(_vm.$n(props.item.amount, 'currency')))]),_c('td',[_vm._v(_vm._s(props.item.dueAt))]),_c('td',[_vm._v(_vm._s(_vm.$t('bankSlip.state.' + props.item.state)))])])]}}],null,true)})],1)]}},{key:"pageText",fn:function(props){return [_vm._v(" Exibindo de "+_vm._s(props.pageStart)+" a "+_vm._s(props.pageStop)+" no total de "+_vm._s(props.itemsLength)+" ")]}}])})],1)]],2)],1),_c('transition',{attrs:{"name":"slide","mode":"out-in","appear":""}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }