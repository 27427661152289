<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-card-text class="py-0 px-0">
        <template v-if="loading > 0">
          <loading class="absolute-center" />
        </template>

        <template v-else>
          <v-card class="transparent elevation-0 card-pt-0">
            <v-card-text class="px-0 py-0">
              <h1 class="main-title mb-6">
                Acordos
              </h1>
            </v-card-text>

            <v-data-table
              class="transparent table-sticky table-clicked"
              no-data-text="Não há negociações criadas"
              item-key="id"
              :options.sync="pagination"
              :headers="headers"
              :items="proposals"
              :footer-props="footerProps"
              :server-items-length="proposals.length"
              :single-expand="true"
              :expanded.sync="expanded">
              <template
                v-slot:item="{ item, expand }"
                class="transparent">
                <tr
                  :class="{'table--selected elevation-3': expand.props.value}"
                  @click="toggleExpand(item)">
                  <td
                    class="checked-action"
                    tabindex="0">
                    <v-icon
                      v-if="expand.props.value"
                      class="v-align-center mr-2"
                      size="20px">
                      {{ $root.icons.state.checked }}
                    </v-icon>
                    <v-icon
                      v-else
                      class="v-align-center mr-2"
                      size="20px">
                      {{ $root.icons.state.unchecked }}
                    </v-icon>
                  </td>
                  <td class="after-checked-action">
                    {{ item.code }}
                  </td>
                  <td>
                    <promise-key
                      :promise="item.case"
                      promise-key="recipient.name" />
                  </td>
                  <td>{{ $n(item.installmentSelected.originalValue, 'currency') }}</td>
                  <td>
                    <v-tooltip
                      top
                      content-class="table_trasparent">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on">
                          <v-icon color="grey lighten-1">
                            info
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-simple-table dense>
                        <tbody>
                          <tr
                            v-for="(item, index) in item.installmentSelected.details"
                            :key="index">
                            <td>{{ $t('calculateParams.' + item.type) }}</td>
                            <td>{{ $n(item.value, 'currency') }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-tooltip>
                    {{ $n(additions(item.installmentSelected.details), 'currency') }}
                  </td>
                  <td>
                    <v-tooltip
                      top
                      content-class="table_trasparent">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on">
                          <v-icon color="grey lighten-1">
                            info
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.installmentSelected.parcel }} x {{ $n(item.installmentSelected.parcelValue, 'currency') }}</span>
                    </v-tooltip>
                    {{ $n(item.installmentSelected.currentValue, 'currency') }}
                  </td>
                  <td>{{ item.updatedAt }}</td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="py-2">
                  <h2 class="subtitle-1 mb-2 darkGray--text">
                    <v-icon
                      size="22px"
                      class="icon-align-text mr-1"
                      color="darkGray">
                      {{ $root.icons.layout.label }}
                    </v-icon>
                    Termo: <v-btn
                      :href="item && item.term && item.term.fileUrl"
                      target="blank"
                      depressed
                      small
                      color="accent">
                      Visualizar
                    </v-btn>
                  </h2>
                  <v-data-table
                    class="transparent table-sticky"
                    :options.sync="bankSlipPagination"
                    :headers="bankSlipHeaders"
                    :items="item.ticketsPayments"
                    hide-default-footer>
                    <template v-slot:item="props">
                      <tr>
                        <td>{{ props.item.numDoc }}</td>
                        <td>{{ props.item.parcel }}</td>
                        <td>{{ $n(props.item.details.correctionIndex, 'currency') }}</td>
                        <td>{{ $n(props.item.details.fine, 'currency') }}</td>
                        <td>{{ $n(props.item.details.interestRate, 'currency') }}</td>
                        <td>{{ $n(props.item.details.fees, 'currency') }}</td>
                        <td>{{ $n(props.item.amount, 'currency') }}</td>
                        <td>{{ props.item.dueAt }}</td>
                        <td>{{ $t('bankSlip.state.' + props.item.state) }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </td>
              </template>
              <template v-slot:pageText="props">
                Exibindo de {{ props.pageStart }} a {{ props.pageStop }} no total de {{ props.itemsLength }}
              </template>
            </v-data-table>
          </v-card>
        </template>
      </v-card-text>
    </v-card>

    <transition
      name="slide"
      mode="out-in"
      appear>
      <router-view />
    </transition>
  </v-container>
</template>

<script>
  import EmptyState from '@/components/ui/EmptyState'
  import Loading from '@/components/ui/Loading'
  import PromiseKey from '@/components/ui/PromiseKey'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      EmptyState,
      Loading,
      PromiseKey
    },
    data () {
      const rowsPerPageItems = [13, 25, 50, 100]

      return {
        expanded: [],
        headers: [
          { text: null, value: 'checked', class: 'checked-action', sortable: false },
          { text: 'Código', value: 'code', class: 'after-checked-action', sortable: false },
          { text: 'Nome', value: 'recipient_name', sortable: false },
          { text: 'Valor Original', value: 'installmentSelected.originalValue', sortable: false },
          { text: 'Acréscimos', value: 'additions', align: 'center', sortable: false },
          { text: 'Valor Negociado', value: 'installmentSelected.currentValue', sortable: false },
          { text: 'Efetivado em', value: 'updated_at', sortable: false }
        ],
        pagination: {
          sortBy: [sessionStorage.getItem('proposalPaginationSortBy') || 'updated_at'],
          sortDesc: [JSON.parse(sessionStorage.getItem('proposalPaginationDescending') || true)],
          page: parseInt(sessionStorage.getItem('proposalPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('proposalPaginationRowsPerPage') || 13),
          rowsPerPageItems: rowsPerPageItems
        },
        bankSlipHeaders: [
          { text: 'Nosso Número', align: 'left', sortable: false, value: 'num_doc' },
          { text: 'Parcela', align: 'left', sortable: false, value: 'parcel' },
          { text: 'Correção Monetária', value: 'proposal', sortable: false },
          { text: 'Multa', value: 'proposal', sortable: false },
          { text: 'Juros', value: 'proposal', sortable: false },
          { text: 'Honorários', value: 'proposal', sortable: false },
          { text: 'Valor', align: 'left', sortable: false, value: 'amount' },
          { text: 'Vencimento', align: 'left', sortable: false, value: 'due_at' },
          { text: 'Status', align: 'left', sortable: false, value: 'state' }
        ],
        bankSlipPagination: {
          sortBy: 'parcel',
          descending: false,
          page: 1,
          rowsPerPage: -1
        },
        proposalId: null,
        footerProps: {
          rowsPerPageItems: rowsPerPageItems,
          rowsPerPageText: 'Mostrar por página'
        }
      }
    },
    computed: {
      ...mapGetters({
        proposals: 'proposal/list',
        loading: 'proposal/listLoading'
      }),
      showActionButton () {
        return this.$can('CaseCreate') && this.cases.length > 0
      }
    },
    watch: {
      pagination: {
        handler (pagination) {
          // sessionStorage.setItem('proposalPaginationSortBy', pagination.sortBy)
          // sessionStorage.setItem('proposalPaginationDescending', pagination.descending)
          // sessionStorage.setItem('proposalPaginationRowsPerPage', pagination.rowsPerPage)
        },
        deep: true
      }
    },
    beforeMount () {
      this.$store.dispatch('proposal/getList')
    },
    mounted () {
      this.setProposalId()
    },
    methods: {
      toggleExpand(item) {
        this.expanded = this.expanded.includes(item) ? [] : [item]
      },
      additions(details) {
        return details.reduce((total, detail) => detail.value + total, 0)
      }
    }
  }
</script>

<style lang="sass">
  .table_trasparent
    .theme--light.v-data-table
      background: transparent
      color: #fff
</style>
